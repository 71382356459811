export const nfiRoadDataEng = [
    {
        id: 1,
        name: "RED LIPS COFFEE",
        location: "55, Haean-ro, Buk-gu, Pohang-si, Gyeongsangbuk-do, South Korea",
        category: "Cafe",
        lat: "36.0535805555402",
        lng: "129.376913685955",
        naverLink: "https://maps.app.goo.gl/rUF4Rochg29QQths8",
        note : "The cafe N.Flying visited when busking in Pohang."
    },
    {
        id: 2,
        name: "Yeongildae Beach",
        location: "685-1, Duho-dong, Buk-gu, Pohang-si, Gyeongsangbuk-do, South Korea",
        category: "Place",
        lat: "36.0572902321339",
        lng: "129.378068992389",
        naverLink: "https://maps.app.goo.gl/3w6XsYsH6tkfSmKf7",
        note : "The busking location of Fly High Project's 'WHERE TO FLY.'"
    },
    {
        id: 3,
        name: "Dalgo Tteokbokki",
        location: "Dalgo Tteokbokki 54, Yaoeeumakdang-ro 39-gil, Dalseo-gu, Daegu, South Korea",
        category: "Food",
        lat: "35.8541511710543",
        lng: "128.554339587787",
        naverLink: "https://maps.app.goo.gl/toZhJcPRCFEdfAUP9",
        note : "The place that brings back memories of Seunghyub's school days."
    },
    {
        id: 4,
        name: "Jipshin Spicy Galbijjim, Yeongdeungpo Branch",
        location: "1F 13, Yeongjung-ro 4-gil, Yeongdeungpo-gu, Seoul, South Korea",
        category: "Food",
        lat: "37.517589659504",
        lng: "126.907737266414",
        naverLink: "https://maps.app.goo.gl/dfxJVJjjLQ2ekbD89",
        note : "Visited by 2IDIOTS"
    },
    {
        id: 5,
        name: "Countryside kimchi pork belly",
        location: "110, World Cup-ro, Mapo-gu, Seoul, South Korea",
        category: "Food",
        lat: "37.5583816474532",
        lng: "126.908345574502",
        naverLink: "https://maps.app.goo.gl/tkuoAeT5xse6oJXd7",
        note : "The samgyeopsal restaurant visited by N.Flying."
    },
    {
        id: 6,
        name: "Brilliant Steamed Monkfish Gangnam Branch",
        location: "12, Gangnam-daero 78-gil, 2nd Floor, Gangnam-gu, Seoul, South Korea",
        category: "Food",
        lat: "37.4948475341971",
        lng: "127.030367339339",
        naverLink: "https://maps.app.goo.gl/8p8d33ZgM6HAhyebA",
        note : "Visited by 2IDIOTS"
    },
    {
        id: 7,
        name: "CAFE VINI",
        location: "17, Achiul-gil, Guri-si, Gyeonggi-do, South Korea",
        category: "Cafe",
        lat: "37.5741246696648",
        lng: "127.120072140662",
        naverLink: "https://maps.app.goo.gl/xxzvgUsVsoY7ah3t6",
        note : "The cafe where Seunghyub went with his friend."
    },
    {
        id: 8,
        name: "ADe:T",
        location: "1F 19, Sangwon 12-gil, Seongdong-gu, Seoul, South Korea",
        category: "Cafe",
        lat: "37.550730034393",
        lng: "127.04981101799",
        naverLink: "https://maps.app.goo.gl/xk9NFp1nPrqFcTuu6",
        note : "The cafe that Seunghyub and Jaehyun frequented."
    },
    {
        id: 9,
        name: "DEUS CAFE",
        location: "134, Samcheong-ro, Jongno-gu, Seoul, South Korea",
        category: "Cafe",
        lat: "37.5872420747219",
        lng: "126.981815611418",
        naverLink: "https://maps.app.goo.gl/tzLiEhAKQGXFmmTm8",
        note : "The cafe where Hweseung and Jaehyun spent time together."
    },
    {
        id: 10,
        name: "Saekjang Rice Mill Cafe",
        location: "2-15, Wonsaekjang-gil, Wansan-gu, Jeonju-si, Jeonbuk-do, Soteh Korea",
        category: "Cafe",
        lat: "35.7975593069138",
        lng: "127.187470102102",
        naverLink: "https://maps.app.goo.gl/puY9kD9uPaHLkSE47",
        note : "Filming location of N.Flying's 'I Like You' music video."
    },
    {
        id: 11,
        name: "Color Signal",
        location: "4F 12, Yeoksam-ro 20-gil, Gangnam-gu, Seoul, South Korea",
        category: "Place",
        lat: "37.4944462999401",
        lng: "127.037624052477",
        naverLink: "https://maps.app.goo.gl/8QLo6G4YdJLczvbu6",
        note : "The place where 2IDIOTS got their personal color diagnosis."

    },
    {
        id: 12,
        name: "Moonlight Ally",
        location: "42, World Cup-ro 36-gil, Mapo-gu, Seoul, South Korea",
        category: "Food",
        lat: "37.5679872004367",
        lng: "126.905192606847",
        naverLink: "https://maps.app.goo.gl/qi3392vfzNBz4s9A7",
        note : "The location from Seunghyub's photos."
    },
    {
        id: 13,
        name: "Myeongdong Charcoal Grilled Dakgalbi",
        location: "10, Geumgang-ro 62beon-gil, Chuncheon-si, Gangwon-do, South Korea",
        category: "Food",
        lat: "37.8794157097117",
        lng: "127.727700847008",
        naverLink: "https://maps.app.goo.gl/Ed5RCr2p1FxyoGwN9",
        note : "The dakgalbi restaurant visited by N.Flying."
    },
    {
        id: 14,
        name: "Cafe TED",
        location: "51, Seongmisan-ro 32-gil, Mapo-gu, Seoul, South Korea",
        category: "Cafe",
        lat: "37.5627401592659",
        lng: "126.922610632303",
        naverLink: "https://maps.app.goo.gl/L9FRD5Q6k9hVTs3KA",
        note : "The location from Seunghyub's photos."
    },
    {
        id: 15,
        name: "Hyundai Premium Outlet SPACE 1",
        location: "50, Dasansunhwan-ro, Namyangju-si, Gyeonggi-do, South Korea",
        category: "Place",
        lat: "37.616257926116",
        lng: "127.152429100255",
        naverLink: "https://maps.app.goo.gl/NXNa58BvgyRCjn6v9",
        note : "The location from Seunghyub's photos."
    },
    {
        id: 16,
        name: "Euljiro V",
        location: "4F 51, Chungmu-ro, Jung-gu, Seoul, South Korea",
        category: "Food",
        lat: "37.5657910103787",
        lng: "126.992497832713",
        naverLink: "https://maps.app.goo.gl/hGhgFwD3R6LHmF8E9",
        note : "Filming location of the 'GOOD BAM' live video." // https://x.com/NFlyingofficial/status/1189060920456044544
    },
    {
        id: 17,
        name: "Banpo Sports Complex",
        location: "30, Sinbanpo-ro 16-gil, Seocho-gu, Seoul, South Korea",
        category: "Place",
        lat: "37.5005376398879",
        lng: "126.99426439255",
        naverLink: "https://maps.app.goo.gl/Rk7tziiHAGPnihMh9",
        note : "The location from Seunghyub's photos."
    },
    {
        id: 18,
        name: "DOWUDDO Seongsu branch",
        location: "18-8, Seoulsup 4-gil, Seongdong-gu, Seoul, South Korea",
        category: "Food",
        lat: "37.5470517097135",
        lng: "127.042673790996",
        naverLink: "https://maps.app.goo.gl/6BiKv1yQJiqWH4C49",
        note : "The rice bowl restaurant that Seunghyub visited."
    },
    {
        id: 19,
        name: "Sool Tak(Soolinneun Siktak) Seongsu branch",
        location: "18, Wangsimni-ro 10-gil, Seongdong-gu, Seoul, South Korea",
        category: "Food",
        lat: "37.5465616785945",
        lng: "127.046435626195",
        naverLink: "https://maps.app.goo.gl/GwovYqCWxzbzHw1MA",
        note : "The bistro bar visited by Seunghyub with his FNC seniors and juniors."
    },
    {
        id: 20,
        name: "Hapcheon Illyu Dwaejigukbap",
        location: "34, Gwangjang-ro, Sasang-gu, Busan, South Korea",
        category: "Food",
        lat: "35.16225471541108",
        lng: "128.98008783271953",
        naverLink: "https://maps.app.goo.gl/5tkKxP9DhPfq4Woz8",
        note : "Seunghyub's favorite pork soup, one of the top three in Busan."
    },
    {
        id: 21,
        name: "Namcheon Green Tea Bingsu",
        location: "28, Suyeong-ro 394beon-gil, Suyeong-gu, Busan, South Korea",
        category: "Cafe",
        lat: "35.1407428384391",
        lng: "129.10881041985093",
        naverLink: "https://maps.app.goo.gl/LjFfsYDLajmPmryv5",
        note : "The old-fashioned patbingsu shop that Seunghyub and Hweseung visited."
    },
    {
        id: 22,
        name: "Haeundae Myeongpoom Hotteok",
        location: "38, Jungdong 1-ro, Haeundae-gu, Busan, South Korea",
        category: "Food",
        lat: "35.1622100514759",
        lng: "129.163126552708",
        naverLink: "https://maps..app.goo.gl/ZhKJYqZUK7XXaB5GA",
        note : "The famous hotteok (Korean pancake) shop visited by Seunghyub and Hweseung."
    },
    {
        id: 23,
        name: "Song-ga-ne Gamja-tang",
        location: "245 Donggyo-ro, Mapo-gu, Seoul, Song-ga-ne Gamja-tang",
        category: "Food",
        lat: "37.5618364940379",
        lng: "126.924050688833",
        naverLink: "https://maps.app.goo.gl/q5DCXSo9Y2tgTCyd8",
        note : "The restaurant that Seunghyub has been visiting since his trainee days."
    },
    {
        id: 24,
        name: "Korean Fresh Raw Fish Restaurant",
        location: "90, Seongdeokjeong-gil, Seongdong-gu, Seoul, South Korea",
        category: "Food",
        lat: "37.5376693827344",
        lng: "127.053399481711",
        naverLink: "https://maps.app.goo.gl/f8apCDEvW2fcDLeu6",
        note : "The raw fish restaurant that N.Flying visited on the first snowy day."
    },
    {
        id: 25,
        name: "SUBJECTIVE CAFE",
        location: "229, Gwangnaru-ro, Seongdong-gu, Seoul, South Korea",
        category: "Cafe",
        lat: "37.549882541627",
        lng: "127.055690503868",
        naverLink: "https://maps.app.goo.gl/eVmhy53ct3DtW86E6",
        note : "The cafe from Seunghyub's Longchamp advertisement photo."
    },
    {
        id: 26,
        name: "Kumachan Onsen Seongsu",
        location: "2F, 5, Yeonmujang 7-gil, Seongdong-gu, Seoul, South Korea",
        category: "Food",
        lat: "37.5432380295407",
        lng: "127.054348975494",
        naverLink: "https://maps.app.goo.gl/sXjW9XP4v72vjZSdA",
        note : "The shabu-shabu restaurant where Dongsung had a meal with his father."
    },
    {
        "id": 27,
        "name": "Chupungnyeong Kal Samgyeopsal",
        "location": "1F, 41, Dosan-daero 51-gil, Gangnam-gu, Seoul, South Korea",
        "category": "Food",
        "lat": "37.5259447243218",
        "lng": "127.038100978755",
        "naverLink": "https://maps.app.goo.gl/vufPx8z9nKpAFmhu6",
        "note": "The restaurant that Seunghyub frequented."
    },
    {
        "id": 28,
        "name": "Saengsaeng Nakji BEXCO Branch",
        "location": "101, Centum Makers Building, 29, Centum 2-ro, Haeundae-gu, Busan, South Korea",
        "category": "Food",
        "lat": "35.1666539973508",
        "lng": "129.133204693814",
        "naverLink": "https://maps.app.goo.gl/KfTx86oCxPX4dgks8",
        "note": "The restaurant visited by N.Flying after the Busan concert."
    },
    {
        "id": 29,
        "name": "Surim Hoetjip",
        "location": "1F, Building A, 15, Haeundaehaebyeon-ro 221beon-gil, Haeundae-gu, Busan, South Korea",
        "category": "Food",
        "lat": "35.1599145493419",
        "lng": "129.156008091841",
        "naverLink": "https://maps.app.goo.gl/sva93wKp8hEVGM5X9",
        "note": "The restaurant where N.Flying ate mulhoe after the Busan concert."
    },
    {
        id: 30,
        name: "Chinese Traditional Cuisine Ling Ling",
        location: "Chinese Traditional Cuisine Ling Ling Dosan-daero, Gangnam-gu, Seoul, South Korea",
        category: "Food",
        lat: "37.5248722572746",
        lng: "127.050379366946",
        naverLink: "https://maps.app.goo.gl/fK64R1q1W8SQiuxeA",
        note : "The Chinese restaurant that Hweseung visited."
    },
    {
        id: 31,
        name: "MitBord Seoul",
        location: "1F, 1, Seongsuil-ro, Seongdong-gu, Seoul, South Korea",
        category: "Food",
        lat: "37.5408654463888",
        lng: "127.047850218608",
        naverLink: "https://maps.app.goo.gl/4hLCvXwwsxRwGHey8",
        note : "2025.03.08 Seunghyub's Instagram"
    },
    {
        id: 32,
        name: "Seobook Myeonok",
        location: "Seobook Myeonok, 199-1, Jayang-ro, Gwangjin-gu, Seoul, South Korea",
        category: "Food",
        lat: "37.5453748311268",
        lng: "127.085295421877",
        naverLink: "https://maps.app.goo.gl/QNLTkNkJKbEfWEbh6",
        note : "Seunghyub's favorite Pyeongyang Naengmyeon restaurant"
    },
    {
        id: 33,
        name: "Daewon Restaurant",
        location: "16-1, Hangang-daero 62na-gil, Yongsan-gu, Seoul, South Korea",
        category: "Food",
        lat: "37.5343464871621",
        lng: "126.974708922854",
        naverLink: "https://maps.app.goo.gl/qzjNQhPpRaTKBjrm7",
        note : "The charcoal-grilled fish was so delicious that N.Flying visited the place again."
    },
    {
        id: 34,
        name: "Mongchon Dakgalbi",
        location: "298, Seokchonhosu-ro, Songpa-gu, Seoul, South Korea",
        category: "Food",
        lat: "37.5115694532177",
        lng: "127.10815851038",
        naverLink: "https://maps.app.goo.gl/1iEQnv6RzZudQkZw9",
        note : "The place Jaehyun visited with friends."
    },
    {
        id: 35,
        name: "Shabu in Seoul Seongsu",
        location: "53, Seongsuil-ro 6-gil, Seongdong-gu, Seoul, South Korea",
        category: "Food",
        lat: "37.5442146717831",
        lng: "127.055789372886",
        naverLink: "https://maps.app.goo.gl/SKPuNHBgWZDp9FZB8",
        note : "The shabu-shabu restaurant Dongsung visited."
    },
    {
        id: 36,
        name: "NUDAKE HAUS DOSAN",
        location: "50, Apgujeong-ro 46-gil, Gangnam-gu, Seoul, South Korea",
        category: "Cafe",
        lat: "37.5253703709931",
        lng: "127.035679523304",
        naverLink: "https://maps.app.goo.gl/MuoR1gCpM9zmPwvQ7",
        note : "2025.03.15 Seunghyub’s Instagram."
    },
];
