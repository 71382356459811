export const profileDataEng = {
    name: "N.Flying",
    debut_date: "2015-05-20",
    debut_song: "Awesome",
    cover_image_url: "/image/nflying_cover_image.jpg",
    members: [
        {
            name: "Lee Seung-hyub (Leader)",
            position: ["Lead Vocal", "Rapper"],
            aka: ["Seungzzang", "Milu", "Seung-ddo-ppi"],
            birthdate: "1992-10-31",
            imageUrl: "/image/member/seunghyub.jpg",
            military: "2023-01-01",
            instagram: "https://www.instagram.com/sssn9_zzzn9/",
            mbti: "ENFP",
        },
        {
            name: "Cha Hun",
            position: ["Guitar", "Chorus"],
            aka: ["Myami", "Dialect Sniper"],
            birthdate: "1994-07-12",
            imageUrl: "/image/member/chahun.jpg",
            military: "2024-09-19",
            instagram: "https://www.instagram.com/cchh_0712",
            mbti: "ISFJ",
        },
        {
            name: "Kim Jae-hyun",
            position: ["Drums"],
            aka: ["Rooftop Drum Guy", "Jjurumi"],
            birthdate: "1994-07-15",
            imageUrl: "/image/member/jaehyun.jpg",
            military: "2025-02-24",
            instagram: "https://www.instagram.com/_.kimjaehyun._",
            mbti: "ENTJ",
        },
        {
            name: "Yoo Hwe-seung",
            position: ["Main vocal"],
            aka: ["Seung-goo", "Malrangi", "Hamang-e"],
            birthdate: "1995-02-28",
            imageUrl: "/image/member/hewseung.jpg",
            military: "2023-01-01",
            instagram: "https://www.instagram.com/hweng_star",
            mbti: "ENFJ",
        },
        {
            name: "Seo Dong-sung",
            position: ["Bass", "Chorus"],
            aka: ["Makkmaengi", "Ddoongttangi"],
            birthdate: "1996-04-09",
            imageUrl: "/image/member/dongsung.jpg",
            military: "2024-11-07",
            instagram: "https://www.instagram.com/9_6_meng22",
            mbti: "INTJ",
        },
    ],
    fandom_name: "N.Fia",
    light_stick: "N.Fi Light Stick",
    official_sites: {
        x: "https://x.com/Nflyingofficial",
        facebook: "https://www.facebook.com/officialnflying",
        instagram: "https://www.instagram.com/letsroll_nf",
        youtube: "https://www.youtube.com/channel/UC8vm0EzlH9mRHwnTLILpQjQ",
        daumcafe: "https://cafe.daum.net/N.Flying"
    }
};
